import React from "react";

export default function UsCashCounter() {
  const [total, setTotal] = React.useState(0);

  const [values, setValues] = React.useState({
    $100: null,
    $50: null,
    $20: null,
    $10: null,
    $5: null,
    $1: null,
    "25¢": null,
    "10¢": null,
    "5¢": null,
    "1¢": null,
  });
  React.useEffect(() => {
    const total =
      values.$100 * 100 +
      values.$50 * 50 +
      values.$20 * 20 +
      values.$10 * 10 +
      values.$5 * 5 +
      values.$1 * 1 +
      values["25¢"] * 0.25 +
      values["10¢"] * 0.1 +
      values["5¢"] * 0.05 +
      values["1¢"] * 0.01;
    setTotal(parseFloat(total).toFixed(2));
  }, [values]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
  };

  return (
    <div className="pl-7 grid justify-center">
      <h1 className="text-3xl font-bold leading-tight text-gray-900 grid justify-center ">
        US Cash Counter
      </h1>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
        <h1 className="text-xl font-bold leading-tight text-gray-900 grid justify-center  ">
          ${total}
        </h1>
      </div>
      <form className="space-y-8 divide-y divide-gray-200 ">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 grid justify-center">
          <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5 grid justify-center ">
            <div className="space-y-2 sm:space-y-5">
              {Object.keys(values).map((key) => (
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor={key}
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 grid justify-center"
                  >
                    <span className=""> {key} &#215;</span>
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="tel"
                      name={key}
                      id={key}
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      value={values[key]}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      pattern="[0-9]+"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </form>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-14">
        <h1 className="text-xl font-bold leading-tight text-gray-900  ">
          ${total}
        </h1>
      </div>
    </div>
  );
}
