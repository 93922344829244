import { Disclosure } from "@headlessui/react";
import App_Icon from "../assets/App_Icon.png";
import CurrencySelector from "./CurrencySelector";

export default function Template(props) {
  return (
    <>
      <div className="min-h-full grid grid-justify-center ">
        <Disclosure
          as="nav"
          className="bg-white border-b border-gray-200 flex flex-justify-center"
        >
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <img
                      className="block lg:hidden h-15 w-auto"
                      src={App_Icon}
                      alt="Vallhund Logo"
                    />
                    <img
                      className="hidden lg:block h-15 w-auto"
                      src={App_Icon}
                      alt="Workflow"
                    />
                    <h1>Vallhund Cash Counter</h1>
                  </div>
                </div>
              </div>
              <CurrencySelector />
            </div>
          </>
        </Disclosure>

        <div className="py-10 ">
          <header></header>
          <main>
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8  ">
              {/* Replace with your content */}
              {props.children}

              {/* /End replace */}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
