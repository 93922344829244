import React from "react";
import { useSelector } from "react-redux";
import CanadianCashCounter from "./components/CanadianCashCounter";
import Template from "./components/Template";
import UsCashCounter from "./components/UsCashCounter";

function App() {
  const selector = useSelector((state) => state.toggle.toggle);
  return (
    <div className="App">
      <Template>
        {!selector ? <CanadianCashCounter /> : <UsCashCounter />}
      </Template>
    </div>
  );
}

export default App;
