/* This example requires Tailwind CSS v2.0+ */
import { Switch } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { toggle } from "../features/toggler/toggleSlice";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CurrencySelector(props) {
  const enabled = useSelector((state) => state.toggle.toggle);
  const dispatch = useDispatch();
  return (
    <div className="sm:-my-px sm:ml-6 sm:flex sm:space-x-3 grid justify-center  ">
      <p>
        <span className=" px-5">CAD</span>
        <Switch
          checked={enabled}
          onChange={() => {
            dispatch(toggle());
          }}
          className={classNames(
            enabled ? "bg-indigo-600" : "bg-gray-200",
            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
            )}
          />
        </Switch>
        <span className=" px-5">USD</span>
      </p>
    </div>
  );
}
